<template>
  <div>
    <v-toolbar dark color="secondary">
      <v-toolbar-title class="white--text">New Client</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="refreshPage">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-stepper v-model="progress">
        <v-stepper-content step="1">
          <v-card flat>
            <v-card-title>Add New Client</v-card-title>
            <v-card-text>
              <v-text-field
                outlined
                filled
                clearable
                label="Account Number"
                ref="account-no"
                v-model="account.serialNo"
                hint="Please enter an account number to add an account. The number is CASE SENSITIVE."
                persistent-hint
                v-on:keyup.enter="progress = 2"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!this.account.serialNo"
                @click="progress = 2"
                >Continue</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-form ref="form" v-model="validated">
            <v-card flat>
              <v-card-title>Account {{ account.serialNo }}</v-card-title>
              <v-card-text>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-text-field
                      outlined
                      prepend-icon="mdi-account-circle"
                      class="pr-md-2"
                      label="First Name"
                      v-model="firstName"
                      v-on:blur="updateName()"
                      :rules="firstNameRules"
                      required
                    ></v-text-field>
                    <v-text-field
                      outlined
                      class="pr-md-2"
                      label="Last Name"
                      v-model="lastName"
                      v-on:blur="updateName()"
                      :rules="lastNameRules"
                      required
                    ></v-text-field>
                  </v-layout>
                  <v-text-field
                    outlined
                    prepend-icon="mdi-email"
                    label="Email"
                    ref="email"
                    v-model="account.email"
                    v-on:blur="emailToLowerCase()"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    prepend-icon="mdi-cellphone"
                    label="IMEI"
                    v-model="account.imei"
                    :rules="imeiRules"
                    :counter="15"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-autocomplete
                    outlined
                    v-model="account.store"
                    :items="retailer.stores"
                    prepend-icon="mdi-storefront"
                    label="Select a Store"
                    item-text="name"
                    item-value="_id"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    outlined
                    v-model="account.auditor"
                    :items="auditors"
                    prepend-icon="mdi-clipboard-search"
                    label="Select an Auditor"
                    item-text="name"
                    item-value="_id"
                  ></v-select>
                </v-flex>

                <v-layout>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="account.emailPackage"
                      label="Include Email"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="account.whatsApp"
                      label="Include WhatsApp"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-form>
          <v-btn @click.native="clear" class="ma-2">Clear</v-btn>
          <v-btn
            color="primary"
            @click.native="saveClient"
            :disabled="!validated"
            class="ma-2"
            >Save</v-btn
          >
        </v-stepper-content>
      </v-stepper>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      progress: 1,
      firstName: "",
      lastName: "",
      auditors: [],
      account: {},
      firstNameRules: [
        (v) => !!v || "First Name is required",
        (v) => v !== "unset" || "You must change the name",
      ],
      lastNameRules: [
        (v) => !!v || "Last Name is required",
        (v) => v !== "unset" || "You must change the name",
      ],
      emailRules: [
        (v) => !!v || "A valid email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
      ],
      imeiRules: [
        (v) => !!v || "A valid IMEI is required",
        (v) => (v && v.length === 15) || "IMEI must be 15 numbers",
      ],
      validated: false,
    };
  },
  props: ["retailer"],
  computed: {
    isValidSerial() {
      return this.account.serialNo.length > 5;
    },
  },
  methods: {
    getAuditors() {
      fetch("https://api.safetelecom.net/api/auditors")
        .then((res) => res.json())
        .then((data) => {
          this.auditors = data;
        });
    },
    updateName() {
      this.firstName = this.firstName.trim();
      this.lastName = this.lastName.trim();
      this.firstName = this.toTitleCase(this.firstName);
      this.lastName = this.toTitleCase(this.lastName);
      this.account.name = this.firstName + " " + this.lastName;
    },
    toTitleCase(string) {
      const regex = /\w\S*/g;
      return string.replace(regex, (word) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      });
    },
    emailToLowerCase() {
      this.account.email = this.account.email.trim().toLowerCase();
    },
    saveClient() {
      if (this.$refs.form.validate()) {
        const data = new URLSearchParams();
        for (const pair of Object.entries(this.account)) {
          data.append(pair[0], pair[1]);
        }
        console.log(data);
        fetch("https://api.safetelecom.net/api/clients", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then(() => {
            this.refreshPage();
            alert("Account Created!");
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        alert("Form not validated!");
      }
    },
    clear() {
      this.$refs.form.reset();
      this.progress = 1;
      this.account.serialNo = "";
    },
    refreshPage() {
      this.auditors = [];
      this.getAuditors();
      this.clear();
    },
  },
  created() {
    this.getAuditors();
  },
  mounted() {
    this.account.retailer = this.retailer._id;
  },
};
</script>
