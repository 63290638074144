<template>
	<v-card
		outlined
		tile
	>
		<v-card-title primary-title>
			<span class="headline">{{ serial }}</span>
			<v-spacer></v-spacer>
			<v-progress-circular
				v-if="isActive"
				indeterminate
				color="green"
			></v-progress-circular>
			<v-menu bottom left>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="getUnlockKey(serial)">
						<v-list-item-title>Get Unlock Key</v-list-item-title>
					</v-list-item>
					<v-list-item @click="refreshDevice(codename, serial)">
						<v-list-item-title>Refresh Phone</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<div id="terminal" class="pa-2" style="height:250px; overflow-y: scroll; background-color: black;">
			<v-flex xs12>
				<pre class="white--text">{{ terminalOutput }}</pre>
			</v-flex>
		</div>
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<h3>Device:<br><pre>{{ codename }}</pre></h3>
					<h3>Status:<br><pre>{{ deviceStatus }}</pre></h3>
					<h3>IMEI:<br><pre>{{ imei }}</pre></h3>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-text-field
						v-model="unlockKey"
						label="Unlock Key"
						disabled
					></v-text-field>
					<v-text-field
						v-model="unlockCode"
						clearable
						label="Unlock Code"
						append-outer-icon="mdi-lock-open"
						@click:append-outer="unlockDevice(serial, unlockCode)"
					></v-text-field>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import axios from 'axios'

export default {
	props: ['device', 'imageCode', 'retailer'],
	data: () => {
		return {
			serial: '',
			codename: '',
			imei: '',
			unlockCode: '',
			unlockKey: '',
			terminalOutput: '',
			deviceStatus: '',
			isActive: false,
			show: false
		}
	},
	created () {
		this.serial = this.device.serial
		this.codename = this.device.codename
		this.imei = this.device.imei
		this.deviceStatus = this.device.securestate
	},
	updated () {
		let container = this.$el.querySelector('#terminal')
		container.scrollTop = container.scrollHeight
	},
	methods: {
		streamMethodToTerm (url, serial) {
			this.isActive = true
			this.terminalOutput = 'STARTING TASK\n'
			let source = new EventSource(url)
			source.addEventListener(serial, e => {
				let data = e.data
				if (data === 'COMPLETED') {
					this.isActive = false
					source.close()
				}
				this.terminalOutput = this.terminalOutput + (data + '\n')
			}, false)

			source.addEventListener('open', () => {
			}, false)
		},
		getUnlockKey (serial) {
			axios.get(`http://${this.retailer.address}/utils/unlockinfo/${serial}`)
			.then(response => {
				this.unlockKey = response.data
			})
		},
		unlockDevice (serial, unlockCode) {
			let url = `http://${this.retailer.address}/utils/unlockdevice/${serial}/${unlockCode}`
			this.streamMethodToTerm(url, serial)
		},
		refreshDevice (codename, serial) {
			this.getUnlockKey()
			let url = `http://${this.retailer.address}/refresh/${codename}/${serial}?apiKey=${this.retailer.uid}`
			this.streamMethodToTerm(url, serial)
		},
		kosherizeDevice (serial) {
			let url = `http://${this.retailer.address}/kosherize/${this.imageCode}/${serial}?apiKey=${this.retailer.uid}`
			this.streamMethodToTerm(url, serial)
		}
	}
}
</script>
