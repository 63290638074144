import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from '@/auth/authGuard'

import ClientsList from '@/routes/ClientsList'
import NewUser from '@/routes/NewUser'
import CurrentApps from '@/routes/CurrentApps'
import UserProfile from '@/routes/UserProfile.vue'
import PhoneTools from '@/routes/PhoneTools.vue'

// import { store } from '../store'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/clients'
		},
		{
			path: "/profile",
			name: "UserProfile",
			component: UserProfile,
			beforeEnter: authGuard
		},
		{
			path: '/new-user',
			name: 'NewUser',
			component: NewUser,
			beforeEnter: authGuard
		},
		{
			path: '/clients',
			name: 'ClientsList',
			component: ClientsList,
			beforeEnter: authGuard
		},
		{
			path: '/tools',
			name: 'PhoneTools',
			component: PhoneTools,
			beforeEnter: authGuard
		},
		{
			path: '/current-apps',
			name: 'CurrentApps',
			component: CurrentApps
		}
	]
})

export default router
