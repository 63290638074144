<template>
	<v-container fill-height>
		<v-layout align-center justify-center>
				<v-container fluid grid-list-sm>
					<v-layout row wrap>
					</v-layout>
					<v-layout row wrap>
						<v-flex
						xs12 sm3 lg2
						v-for="app in apps"
						:key="app._id"
						>
							<v-card flat tile>
								<v-img
								:src="`https://updates.safetelecom.net/update/store/repo/icons/${app.metadata.icon}`"
								style="height: 150px"
								contain
								>
								</v-img>
								<v-card-title primary-title>
									<div>
										<span>{{ app.metadata.name }}</span>
									</div>
								</v-card-title>
							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
		</v-layout>
		<v-btn
			dark
			fixed
			fab
			bottom
			right
			color="pink"
			@click="refreshPage"
		>
			<v-icon>refresh</v-icon>
		</v-btn>
	</v-container>
</template>
<script>
import axios from 'axios'

export default {
	data: () => {
		return {
			apps: []
		}
	},
	methods: {
		getApps () {
			axios.get('https://api.safetelecom.net/api/apps')
			.then(response => {
				this.apps = response.data
			})
		},
		refreshPage () {
			location.reload()
		}
	},
	created () {
		this.getApps()
	}
}
</script>
