import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App'
import router from '@/router'

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json"

// Import the plugin here
import { Auth0Plugin } from "./auth"

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.config.productionTip = false

Vue.prototype.$localStorage = new Vue({
	data: {
		retailer: { licenses: 0 },
		isConnected: null
	}
})

new Vue({
	vuetify,
	router,
	head: {
		meta: [
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
			}
		]
	},
	render: h => h(App)
  }).$mount('#app')