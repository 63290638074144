<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title class="white--text">Phone Tools</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				icon
				@click="refreshAll"
				:disabled="!devices"
			>
				<v-icon>mdi-lock-open</v-icon>
			</v-btn>
			<v-btn
				icon
				@click="getAllKeys"
				:disabled="!devices"
			>
				<v-icon>mdi-key</v-icon>
			</v-btn>
			<v-btn
				icon
				@click="detectDevices"
			>
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</v-toolbar>
		<v-container fluid>
			<v-row>
				<v-col cols="12">
				<v-card
					class="pa-2"
					outlined
					tile
				>
					<v-card-title primary-title>
						<v-select
							v-model="selectedDevice"
							:items="retailer.devices"
							item-text="name"
							item-value="value"
							return-object
							label="Which model are you koshering?"
							outlined
						></v-select>
					</v-card-title>
					<v-card-text v-if="selectedDevice.fileList">
						<v-progress-linear
							v-model="combinedProgress"
							height="10"
							rounded
						>
						</v-progress-linear>
					</v-card-text>
					<v-card-actions v-if="selectedDevice.fileList">
						<v-spacer></v-spacer>
						<v-btn text @click="downloadAll">Download Files</v-btn>
						<v-btn text @click="kosherizeAll" :disabled="combinedProgress < 100">Kosherize</v-btn>
					</v-card-actions>
				</v-card>
				</v-col>
			</v-row>
			<v-row v-if="devices">
				<v-col cols="6" v-for="device in devices" :key="device.serial">
						<device-terminal :retailer="retailer" :device="device" :imageCode="selectedDevice.codename"></device-terminal>
				</v-col>
			</v-row>
			<v-row v-else-if="loading">
				<v-col cols="12">
					<v-row justify="center">
					<v-card
						class="pa-3"
						outlined
						tile
					>
						<v-card-title class="justify-center">
							<span>Loading Devices...</span>
						</v-card-title>
						<v-card-text class="text-center">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-card-text>
					</v-card>
					</v-row>
				</v-col>
			</v-row>
			<v-row align="center" v-else>
				<v-col cols="12">
					<v-row justify="center">
					<v-card
						class="pa-3"
						outlined
						tile
					>
						<v-card-title class="justify-center">No Devices Detected</v-card-title>
						<v-card-text class="text-center">
							<v-btn color="secondary" @click="detectDevices">Detect Devices</v-btn>
						</v-card-text>
					</v-card>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import axios from 'axios'
import DeviceTerminal from '@/components/DeviceTerminal'

export default {
	data: () => {
		return {
			devices: null,
			selectedDevice: {},
			loading: false,
			progressArray: [],
			combinedProgress: 0
		}
	},
	components: {
		DeviceTerminal
	},
	watch: {
		userProfile () {
			this.detectDevices()
		}
	},
	props: ["retailer"],
	methods: {
		detectDevices () {
			this.devices = null
			this.loading = true
			axios.get(`http://${this.retailer.address}/utils/deviceinfo`)
			.then(response => {
				if (response.data) {
					this.devices = response.data.devices
					this.loading = false
				}
			})
		},
		downloadAll () {
			const files = this.selectedDevice.fileList
			//axios.get(`http://${this.retailer.address}/utils/cleanup`)
			//.then(response => console.log)
			files.forEach((file, index) => {
				const source = new EventSource(`http://${this.retailer.address}/utils/download?srcFile=${file}`)
				source.addEventListener(file, e => {
					this.progressArray[index] = parseInt(e.data)
					this.combinedProgress = this.progressArray.reduce((a, b) => a + b, 0) / files.length
				}, false)
				source.addEventListener('error', e => {
					if (e.eventPhase == EventSource.CLOSED) source.close()
				}, false)
			})
		},
		kosherizeAll () {
			if (this.retailer.licenses < this.$children.length - 1) {
				alert('Sorry, but you don\'t have enough licenses to kosherize these phones. Please contact support@safetelecom.net to purchase more credits.')
			} else {
				this.$children.forEach(child => {
					if (child.serial) {
						child.kosherizeDevice(child.serial, this.imageCode)
						// this.$store.commit('decrementCredits', 1)
					}
				})
			}
		},
		refreshAll () {
			this.$children.forEach(child => {
				if (child.serial) {
					child.refreshDevice(child.codename, child.serial)
				}
			})
		},
		getAllKeys () {
			this.$children.forEach(child => {
				if (child.serial) {
					child.getUnlockKey(child.serial)
				}
			})
		}
	}
}
</script>
