<template>
	<v-app>
		<v-navigation-drawer
		fixed
		app
		router
		v-model="drawer"
		clipped
		>
			<v-list>
				<v-subheader>Account</v-subheader>
				<v-list-item>
					<v-list-item-avatar>
						<img :src="`https://ui-avatars.com/api/?name=${retailer.name}&length=1`" alt="">
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ retailer.name }}</v-list-item-title>
						<v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template>
				<div class="pa-2" v-if="!$auth.loading">
					<v-btn color="primary" block v-if="!$auth.isAuthenticated" @click="login">Login</v-btn>
					<v-btn color="primary" block v-if="$auth.isAuthenticated" @click="logout">Logout</v-btn>
				</div>
			</template>
			<v-divider></v-divider>
			<v-list>
				<v-subheader>Options</v-subheader>
				<template v-for="item in items">
					<v-list-item :to="item.path" :key="item.text">
						<v-list-item-action>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
							{{ item.text }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<v-divider></v-divider>
			<v-list>
				<v-subheader>Status</v-subheader>
				<v-list-item v-if="$localStorage.isConnected">
					<v-list-item-content>
						<v-list-item-action-text>
						<v-text-field
							v-model="retailer.address"
							label="Server Address"
							filled
							disabled
						></v-text-field>
						</v-list-item-action-text>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="retailer.licenses > 0" color="green darken-2">
					<v-list-item-action>
						<v-icon large color="green darken-2">mdi-wallet</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ retailer.licenses }} Credits Left</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-else color="red darken-2">
					<v-list-item-action>
						<v-icon large color="red darken-2">mdi-wallet</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>No Credits Left</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="$localStorage.isConnected" color="green darken-2">
					<v-list-item-action>
						<v-icon large color="green darken-2">mdi-wifi-strength-4</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Network Connected</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-else color="red darken-2">
					<v-list-item-action>
						<v-icon large color="red darken-2">mdi-wifi-strength-4</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Network Disconnected</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar
			color="indigo"
			dark
			fixed
			app
			clipped-left
		>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>KosherOS Retail Console</v-toolbar-title>
		</v-app-bar>
		<v-main>
			<router-view :retailer="retailer" />
		</v-main>
		<v-footer app class="pa-3">
			<v-layout row nowrap justify-space-around>
				<div class="status-icons">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-icon px-2 v-on="on" :color="retailer.licenses > 0 ? 'green darken-2' : 'red darken-2'">mdi-wallet</v-icon>&nbsp;
						</template>
						<span v-if="retailer.licenses > 0">{{ retailer.licenses }}&nbsp;Licenses</span>
						<span v-else>No Licenses Left</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-icon px-2 v-on="on" :color="$localStorage.isConnected ? 'green darken-2' : 'red darken-2'">mdi-wifi-strength-4</v-icon>
						</template>
						<span v-if="$localStorage.isConnected">Network Connected</span>
						<span v-else>Network Disconnected</span>
					</v-tooltip>
				</div>
				<span class="">&copy; 2020 SafeTelecom</span>
			</v-layout>
		</v-footer>
	</v-app>
</template>

<script>
export default {
	data () {
		return {
			drawer: null,
			items: [
				{ icon: 'mdi-account-multiple', text: 'Clients', path: '/clients' },
				{ icon: 'mdi-account-plus', text: 'New Client', path: '/new-user' },
				{ icon: 'mdi-wrench', text: 'Phone Tools', path: '/tools' }
			],
			tools: [
				{ icon: 'phonelink_setup', text: 'Kosherize Phone', path: '/kosherize' },
				{ icon: 'phonelink_erase', text: 'Refresh Phones', path: '/refresh' }
			]
		}
	},
	computed: {
		retailer () {
			if (!this.$auth.loading) {
				var retailer = this.$auth.claims["http://safetelecom/safetelecom"]
				this.initWS(retailer.address, retailer.uid)
				return retailer
			}
			return { name: 'null', picture: 'unset', licenses: 0 }
		}
	},
	methods: {
		login () {
			this.$auth.loginWithRedirect();
		},
		logout () {
			this.$auth.logout({
				returnTo: window.location.origin
			});
		},
		initWS (address, uid) {
			let source = new EventSource(`http://${address}/utils/ping/${uid}`)
			source.addEventListener('ping', (event) => {
				var response = JSON.parse(event.data)
				this.retailer.licenses = response.licenses
				this.retailer.address = response.address
			}, false)
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			source.addEventListener('open', () => { this.$localStorage.isConnected = true }, false)
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			source.addEventListener('error', () => { this.$localStorage.isConnected = false }, false)
		}
	}
}
</script>

<style>
body {
	padding-top: constant(safe-area-inset-top);
	padding-top: env(safe-area-inset-top);
}
.footer{ /* Apply this to v-bottom-nav if necessary. */
	margin-bottom: constant(safe-area-inset-bottom);
	margin-bottom: env(safe-area-inset-bottom);
}
</style>
