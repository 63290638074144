<template>
<div>
		<v-toolbar dark color="secondary">
			<v-toolbar-title
				class="white--text"
			>{{ retailer.name }} Clients</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="searchTerm"
				hide-details
				prepend-icon="mdi-magnify"
				clearable
				label="Search"
				single-line
			></v-text-field>
			<v-btn
				icon
				@click="refreshPage"
			>
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</v-toolbar>
		<v-card>
				<v-data-table
					dense
					:search="searchTerm"
					:loading="dataLoading"
					:headers="headers"
					:items="clients"
					:footer-props="{
						'items-per-page-options': [25, 50, 100, 500, { 'text': 'All', 'value': -1 }]
					}"
					:sort-by="['lastUpdated']"
					:sort-desc="[true]"
					:items-per-page="50"
					item-key="_id"
					must-sort
					class="elevation-1"
					show-expand
					:single-expand="singleExpand"
					:expanded.sync="expanded"
				>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<v-card>
								<v-card-title>{{ item.name }}'s Apps</v-card-title>
								<v-card-text>
									<div class="sm-12 md-6"
										v-for="app in item.appNames"
										:key="app"
									>
										{{ app }}
									</div>
								</v-card-text>
							</v-card>
						</td>
					</template>
					<template v-slot:[`item.phoneNumber`]="{ item }">{{ item.phoneNumber ? new PhoneNumber( item.phoneNumber.toString(), 'US' ).toJSON()['number']['national'] : 'unset' }}</template>
					<template v-slot:[`item.lastUpdated`]="{ item }">{{ moment(item.lastUpdated).format('M/D/YYYY h:mm a') }}</template>
					<template v-slot:[`item.emailPackage`]="{ item }"><v-icon>{{ item.emailPackage ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></template>
					<template v-slot:[`item.whatsApp`]="{ item }"><v-icon>{{ item.whatsApp ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></template>
					<template v-slot:[`item.lastCheckIn`]="{ item }">{{ moment(item.lastCheckIn).calendar(null, { sameElse: 'M-D-YYYY h:mm:ss a' }) }}</template>
					<template v-slot:[`item.latestBuildDate`]="{ item }">{{ item.latestBuildDate ? moment(item.latestBuildDate).format('LL') : '' }}</template>
			</v-data-table>
		</v-card>
	</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import PhoneNumber from 'awesome-phonenumber'

export default {
	data () {
		return {
			dataLoading: true,
			searchTerm: '',
			moment: moment,
			search: '',
			rows: [50, { 'text': 'All', 'value': -1 }],
			pagination: { 'sortBy': 'lastUpdated', 'descending': true },
			headers: [
				{ text: 'Serial Number', value: 'serialNo' },
				{ text: 'Name', value: 'name' },
				{ text: 'Phone', value: 'phoneNumber' },
				{ text: 'Device', value: 'device' },
				{ text: 'Auditor', value: 'auditor.name' },
				{ text: 'Store', value: 'store.name' },
				{ text: 'IMEI', value: 'imei' },
				{ text: 'Carrier', value: 'carrier' },
				{ text: 'Added', value: 'lastUpdated' },
				{ text: 'Latest Build', value: 'latestBuildDate' },
				{ text: 'Email', value: 'emailPackage' },
				{ text: 'WhatsApp', value: 'whatsApp' },
				{ text: 'Checked In', value: 'lastCheckIn' }

			],
			expanded: [],
			singleExpand: true,
			clients: []
		}
	},
	computed: {
		PhoneNumber () {
			return PhoneNumber
		}
	},
	props: ['retailer'],
	methods: {
		getClients () {
			this.dataLoading = true
			axios.get(`https://api.safetelecom.net/api/v2/${this.retailer._id}/clients`)
			.then(response => {
				this.clients = response.data
				this.dataLoading = false
			})
		},
		refreshPage () {
			this.clients = []
			this.getClients()
		}
	},
	created () {
		this.getClients()
	}
}
</script>
